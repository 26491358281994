import { clsx } from 'clsx';
import React, { type PropsWithChildren } from 'react';
import { twMerge } from 'tailwind-merge';

import { BaseButton } from '@/client/design-system/components/base/button';

export enum ButtonVariant {
  Primary = 'primary',
  Secondary = 'secondary',
  Transparent = 'transparent',
  TextOnly = 'textonly',
  Active = 'active',
  Destructive = 'destructive',
  Affirmative = 'affirmative',
  Authorative = 'authorative',
}

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string;
  disabled?: boolean;
  loading?: boolean | string;
  shadow?: boolean;
  variant?: ButtonVariant;
}

// TODO: These aren't right. We don't have a complete design system, so we've just made some stuff up e.g. disabled states.
const variantClassNames: Record<ButtonVariant, (props: ButtonProps) => string> = {
  [ButtonVariant.Primary]: ({ disabled, loading, shadow = true }) =>
    clsx(
      'border border-control-stroke',
      disabled ? 'bg-blue-light-5 text-secondary' : 'bg-control-background text-inverse',
      shadow &&
        (disabled
          ? 'shadow-[inset_0_0_0_1px_rgba(0,0,0,0.05)]'
          : 'shadow-[0_1px_1px_0_rgba(0,0,0,0.1),0_0_0_1px_rgba(0,0,0,0.1),0_8px_4px_-4px_rgba(0,0,0,0.15)]'),
      !loading && !disabled && 'hover:bg-control-hover active:scale-95',
      shadow && loading && !disabled && 'active:shadow-[0_1px_1px_0_rgba(0,0,0,0.1),0_0_0_1px_rgba(0,0,0,0.1)]'
    ),
  [ButtonVariant.Secondary]: ({ disabled, loading, shadow = true }) =>
    clsx(
      'border border-control-secondary-stroke',
      disabled ? 'bg-transparent text-secondary' : 'bg-control-secondary text-primary',
      shadow &&
        (disabled
          ? 'shadow-[inset_0_0_0_1px_rgba(0,0,0,0.05)]'
          : 'shadow-[0px_6px_12px_-6px_rgba(0,0,0,0.10),0px_0px_0px_1px_rgba(0,0,0,0.05),0px_0px_1px_1px_rgba(0,0,0,0.05)]'),
      !loading && !disabled && 'hover:bg-control-secondary-hover active:scale-95'
    ),
  [ButtonVariant.Transparent]: ({ disabled, loading, shadow = true }) =>
    clsx(
      'border border-control-secondary-stroke',
      disabled ? 'bg-blue-light-5 text-blue-light-1' : 'text-primary',
      shadow &&
        (disabled
          ? 'shadow-[inset_0_0_0_1px_rgba(0,0,0,0.05)]'
          : 'shadow-[0px_6px_12px_-6px_rgba(0,0,0,0.10),0px_0px_0px_1px_rgba(0,0,0,0.05),0px_0px_1px_1px_rgba(0,0,0,0.05)]'),
      !loading && !disabled && 'hover:bg-alpha-5 active:scale-95'
    ),
  [ButtonVariant.TextOnly]: ({ disabled, loading, shadow = true }) =>
    clsx(
      disabled ? 'bg-blue-light-5 text-blue-light-1' : 'text-blue-accent-3',
      shadow &&
        (disabled
          ? 'shadow-[inset_0_0_0_1px_rgba(0,0,0,0.05)]'
          : 'shadow-[0px_6px_12px_-6px_rgba(0,0,0,0.10),0px_0px_0px_1px_rgba(0,0,0,0.05),0px_0px_1px_1px_rgba(0,0,0,0.05)]'),
      !loading && !disabled && 'hover:bg-alpha-5 active:scale-95'
    ),
  [ButtonVariant.Active]: ({ disabled, loading, shadow = true }) =>
    clsx(
      'border border-control-active-stroke',
      disabled ? 'bg-blue-light-5 text-blue-light-1' : 'bg-control-active text-white',
      shadow &&
        (disabled
          ? 'shadow-[inset_0_0_0_1px_rgba(0,0,0,0.05)]'
          : 'shadow-[0px_6px_12px_-6px_rgba(0,0,0,0.10),0px_0px_0px_1px_rgba(0,0,0,0.05),0px_0px_1px_1px_rgba(0,0,0,0.05)]'),
      !loading && !disabled && 'hover:bg-control-active-hover active:scale-95'
    ),
  [ButtonVariant.Destructive]: ({ disabled, loading, shadow = true }) =>
    clsx(
      'border border-control-destructive-stroke',
      disabled ? 'bg-blue-light-5 text-blue-light-1' : 'bg-control-destructive text-destructive',
      shadow &&
        (disabled
          ? 'shadow-[inset_0_0_0_1px_rgba(0,0,0,0.05)]'
          : 'shadow-[0px_6px_12px_-6px_rgba(0,0,0,0.10),0px_0px_0px_1px_rgba(239,68,68,0.2),0px_0px_1px_1px_rgba(0,0,0,0.05)]'),
      !loading && !disabled && 'hover:bg-control-destructive-hover active:scale-95'
    ),
  [ButtonVariant.Affirmative]: ({ disabled, loading, shadow = true }) =>
    clsx(
      disabled ? 'bg-green-glass text-green-glass' : 'border border-control-stroke bg-green text-primary',
      shadow &&
        (disabled
          ? 'shadow-[inset_0_0_0_1px_rgba(0,0,0,0.05)]'
          : 'shadow-[0_1px_1px_0_rgba(0,0,0,0.1),0_0_0_1px_rgba(0,0,0,0.1),0_8px_4px_-4px_rgba(0,0,0,0.15)]'),
      !loading &&
        !disabled &&
        'hover:bg-green/85 active:scale-95 active:shadow-[0_1px_1px_0_rgba(0,0,0,0.1),0_0_0_1px_rgba(0,0,0,0.1)]'
    ),
  [ButtonVariant.Authorative]: ({ disabled, loading, shadow = true }) =>
    clsx(
      disabled ? 'bg-green-alt/90 text-white/90' : 'bg-green-alt text-white',
      shadow &&
        (disabled
          ? 'shadow-[inset_0_0_0_1px_rgba(0,0,0,0.05)]'
          : 'shadow-[0_1px_1px_0_rgba(0,0,0,0.1),0_0_0_1px_rgba(0,0,0,0.1),0_8px_4px_-4px_rgba(0,0,0,0.15)]'),
      !loading &&
        !disabled &&
        'hover:bg-green-alt/90 active:scale-95 active:shadow-[0_1px_1px_0_rgba(0,0,0,0.1),0_0_0_1px_rgba(0,0,0,0.1)]'
    ),
};

export const Button = (props: PropsWithChildren<ButtonProps>) => {
  const { children, className, loading, disabled, shadow, variant = ButtonVariant.Primary, ...buttonProps } = props;

  return (
    <BaseButton
      className={clsx(
        twMerge('h-10 rounded-[10px] transition-all text-regular-plus', className),
        variantClassNames[variant](props)
      )}
      loading={loading}
      disabled={disabled}
      {...buttonProps}
    >
      {children}
    </BaseButton>
  );
};
