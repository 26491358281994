import type { ChannelAssociatedObjectEventProperties } from '@/client/features/analytics/events/properties/channels';
import type { DefineTrackingEvents, EventSchemaProperties } from '@/client/features/analytics/schema';
import type { Tutorial_Difficulty } from '@/generated/graphql/global-types.anonymous';
import type { Channel_Type } from '@/generated/graphql/global-types.user';
import type { FlattenedLeftJoin } from '@/shared/types/flattened-left-join';
import type { MinecraftEdition } from '@/types/minecraft';

export enum TrackingSource {
  accountSelector = 'accountSelector',
  callContext = 'callContext',
  callInterface = 'callInterface',
  club = 'club',
  clubCalls = 'clubCalls',
  clubOnboarding = 'clubOnboarding',
  clubBookOnboarding = 'clubBookOnboarding',
  competition = 'competition',
  feed = 'feed',
  join = 'join',
  leftSidebar = 'leftSidebar',
  notifications = 'notifications',
  notFound = 'notFound',
  project = 'project',
  projects = 'projects',
  room = 'room',
  signup = 'signup',
  store = 'store',
  team = 'team',
  tutorials = 'tutorials',
  upgradeModal = 'upgradeModal',
  userSettings = 'userSettings',
}

export type TrackingEvents = DefineTrackingEvents<{
  activityCall: {
    joined: {
      competitionId: string;
      competitionName: string;
      joinedDate: string;
    };
    quit: {
      competitionId: string;
      competitionName: string;
      quitDate: string;
    };
  };
  breakoutCall: {
    joined: {
      breakoutId: string;
      joinedDate: string;
    };
    quit: {
      breakoutId: string;
      breakoutName: string;
      quitDate: string;
    };
  };
  call: {
    connected: FlattenedLeftJoin<
      {
        channelId: string;
        channelMemberCount: number;
        channelName?: null | string;
        channelType: Channel_Type;
        voiceParticipantCount: number;
        voiceSessionId: string;
      },
      ChannelAssociatedObjectEventProperties
    >;
    disconnected: FlattenedLeftJoin<
      {
        channelId: string;
      },
      ChannelAssociatedObjectEventProperties
    >;
    leavingDueToInactivity: FlattenedLeftJoin<
      {
        channelId: string;
        voiceSessionId: string;
      },
      ChannelAssociatedObjectEventProperties
    >;
  };
  callConnectTrigger: {
    clicked: FlattenedLeftJoin<
      {
        activeMemberCount: number;
        channelId: string;
        channelMemberCount: number;
        channelName?: null | string;
        channelType: Channel_Type;
      },
      ChannelAssociatedObjectEventProperties
    >;
  };
  callDisconnectButton: {
    clicked: FlattenedLeftJoin<
      {
        channelId: string;
        voiceSessionId: string;
      },
      ChannelAssociatedObjectEventProperties
    >;
  };
  callHandRaisedButton: {
    clicked: FlattenedLeftJoin<
      {
        channelId: string;
        togglingOn: boolean;
        voiceSessionId: string;
      },
      ChannelAssociatedObjectEventProperties
    >;
  };
  callLink: {
    clicked: FlattenedLeftJoin<
      {
        activeMemberCount: number;
        channelId: string;
        channelMemberCount: number;
        channelName?: null | string;
        channelType: Channel_Type;
      },
      ChannelAssociatedObjectEventProperties
    >;
  };
  callMuteButton: {
    clicked: FlattenedLeftJoin<
      {
        channelId: string;
        togglingOn: boolean;
        voiceSessionId: string;
      },
      ChannelAssociatedObjectEventProperties
    >;
  };
  callScreenShareButton: {
    clicked: FlattenedLeftJoin<
      {
        channelId: string;
        togglingOn: boolean;
        voiceSessionId: string;
      },
      ChannelAssociatedObjectEventProperties
    >;
  };
  callWebCamButton: {
    clicked: FlattenedLeftJoin<
      {
        channelId: string;
        togglingOn: boolean;
        voiceSessionId: string;
      },
      ChannelAssociatedObjectEventProperties
    >;
  };
  channelAssociatedObjectLink: {
    clicked: FlattenedLeftJoin<
      {
        channelId: string;
        channelName?: null | string;
        channelType?: null | Channel_Type;
      },
      ChannelAssociatedObjectEventProperties
    >;
  };
  channelChatFirstMessageButton: {
    clicked: FlattenedLeftJoin<
      {
        channelId: string;
        message: string;
        messageId: string;
      },
      ChannelAssociatedObjectEventProperties
    >;
  };
  competitionDropdownSection: {
    clicked: {
      title: string;
    };
  };
  enderLogo: {
    clicked: {};
  };
  feedbackForm: {
    submitted: {
      associatedObject?: string;
      sentiment?: null | string;
      text: string;
    };
  };
  feedbackModal: {
    closed: {};
    opened: {};
    reset: {};
  };
  joinMinecraftContinueButton: {
    clicked: {};
  };
  liveEvent: {
    viewed: {
      liveEventId: string;
      liveEventName: string;
    };
  };
  logOutButton: {
    clicked: {};
  };
  loginLink: {
    clicked: {};
  };
  minecraftAccount: {
    linked: {
      linkedMinecraftId?: string;
      linkedMinecraftUsername?: string;
      linkedXboxId?: string;
      linkedXboxUsername?: string;
      updatedAt: Date;
    };
    unlinked: {
      linkedMinecraftId?: null;
      linkedMinecraftUsername?: null;
      linkedXboxId?: null;
      linkedXboxUsername?: null;
    };
  };
  minecraftAccountLinkButton: {
    clicked: {};
  };
  minecraftAccountLinkErrorMessage: {
    viewed: {};
  };
  minecraftAccountLinkForm: {
    submitted: {
      minecraftAccountEdition: MinecraftEdition[];
      minecraftAccountUsername?: string;
    };
  };
  minecraftAccountLinkModal: {
    closed: {};
    opened: {};
  };
  minecraftServerLink: {
    copied: {};
  };
  minecrarftAccountLinkErrorButton: {
    clicked: {};
  };
  room: {
    viewed: {
      roomId: string;
      roomName: string;
      roomUrlName: string;
      clubId: string;
      clubName: string;
      clubUrlName: string;
    };
  };
  roomCall: {
    joined: {
      roomId: string;
      roomName: string;
      joinedDate: string;
    };
    quit: {
      roomId: string;
      roomName: string;
      quitDate: string;
    };
  };
  roomCard: {
    clicked: {
      roomId: string;
      roomName: string;
      roomStatus: string;
      roomUrl: string;
      clubUrl: string;
    };
  };
  sidebarNavigation: {
    clicked: {
      itemLabel: string;
    };
  };
  signupButton: {
    clicked: {};
  };
  signupLink: {
    clicked: {};
  };
  switchMinecraftAccountsButton: {
    clicked: {
      oldEdition?: string;
      oldUsername?: string;
      newEdition?: string;
      newUsername?: string;
    };
  };
  switchMinecraftAccountsCancelButton: {
    clicked: {
      oldEdition?: string;
      oldUsername?: string;
      newEdition?: string;
      newUsername?: string;
    };
  };
  switchMinecraftAccountsMessage: {
    viewed: {
      oldEdition?: string;
      oldUsername?: string;
      newEdition?: string;
      newUsername?: string;
    };
  };
  switchProfileButton: {
    clicked: {};
  };
  tutorialCard: {
    clicked: {
      tagId?: string;
      tagName?: string;
      tutorialDifficulty: Tutorial_Difficulty;
      tutorialId: string;
      tutorialName: string;
      tutorialOrder: number;
    };
  };
  user: {
    followed: {
      followedBack: boolean;
      followersCount: number;
      followingCount: number;
      projectsCount: number;
      userId: string;
      username?: null | string;
    };
    loggedOut: {};
    signedUp: {
      birthday: Date;
      email?: string;
      inviteCode?: string;
      username?: string;
    };
    unfollowed: {
      followedBack: boolean;
      followersCount: number;
      followingCount: number;
      projectsCount: number;
      userId: string;
      username?: null | string;
    };
    verifiedPhone: {};
  };
  userAvatar: {
    clicked: {
      followersCount?: number;
      followingCount?: number;
      projectsCount: number;
      userId: string;
      username: string;
    };
    hovered: {
      followersCount: number;
      followingCount: number;
      projectsCount: number;
      userId: string;
      username: string;
    };
    updated: {
      avatar: string;
    };
  };
  userBalance: {
    clicked: {};
  };
  userLoginForm: {
    submitted: {
      channel: 'email';
    };
  };
  userProfile: {
    updated: {
      emailUpdated: boolean;
      firstNameUpdated: boolean;
      lastNameUpdated: boolean;
      usernameUpdated: boolean;
    };
  };
  userProfileUpdateForm: {
    submitted: {
      emailUpdated: boolean;
      firstNameUpdated: boolean;
      lastNameUpdated: boolean;
      usernameUpdated: boolean;
    };
  };
  userSignupForm: {
    submitted: {
      birthday: Date;
      channel: 'email';
      email?: string | null;
      inviteCode?: string;
      username?: string;
    };
  };
}>;

export type TrackingEvent<O extends keyof TrackingEvents, A extends keyof TrackingEvents[O]> = {
  object: O;
  action: A;
  source?: TrackingSource;
} & EventSchemaProperties<TrackingEvents, O, A>;
