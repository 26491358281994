import { clsx } from 'clsx';
import React from 'react';
import type {
  Control,
  FieldError,
  FieldErrorsImpl,
  FieldPath,
  FieldValues,
  Merge,
  UseFormRegisterReturn,
  UseFormSetValue,
} from 'react-hook-form';

type EnderRegisterProps<TFieldName extends string> = Omit<
  UseFormRegisterReturn<TFieldName>,
  'id' | 'disabled' | 'className' | 'min' | 'max'
>;

export interface InputProps<
  TFormValues extends FieldValues,
  TFieldName extends FieldPath<TFormValues> = FieldPath<TFormValues>,
  TInputElement extends HTMLElement = HTMLInputElement,
> extends Omit<
    React.DetailedHTMLProps<React.InputHTMLAttributes<TInputElement>, TInputElement>,
    keyof EnderRegisterProps<TFieldName>
  > {
  id?: string;
  label?: string;
  placeholder?: string;
  register: Omit<UseFormRegisterReturn<TFieldName>, 'id' | 'disabled' | 'className'>;
  control: Control<TFormValues>;
  setValue: UseFormSetValue<TFormValues>;
  error?: null | string | FieldError | Merge<FieldError, FieldErrorsImpl<any>>;
  hideError?: boolean;
  disabled?: boolean;
  containerClassName?: string;
  innerContainerClassName?: string;
  labelClassName?: string;
  inputClassName?: string;
  errorClassName?: string;
  childrenClassName?: string;
}

// TODO: Decouple react-hook-form
export const BaseInput = <TFormValues extends FieldValues, TFieldName extends FieldPath<TFormValues>>({
  id,
  label,
  placeholder,
  register,
  control,
  setValue,
  error,
  hideError = false,
  disabled = false,
  containerClassName,
  innerContainerClassName,
  labelClassName,
  inputClassName,
  errorClassName,
  childrenClassName,
  children,
  style,
  ...props
}: InputProps<TFormValues, TFieldName>): JSX.Element => {
  return (
    <div className={clsx(containerClassName, props.hidden && 'hidden')}>
      {label && (
        <label htmlFor={id} className={labelClassName}>
          {label}
        </label>
      )}
      <div
        className={`flex flex-row flex-nowrap items-center${
          innerContainerClassName ? ' ' + innerContainerClassName : ''
        }`}
      >
        <input
          {...register}
          {...props}
          id={id}
          className={clsx('flex-none w-full', inputClassName)}
          style={style}
          placeholder={placeholder}
          disabled={disabled}
        />
        <div className='pointer-events-none ml-[-100%] w-full -scale-x-100'>
          <div className={clsx('pointer-events-auto w-fit -scale-x-100', childrenClassName)}>{children}</div>
        </div>
      </div>
      {error && !hideError && <p className={errorClassName}>{(error as any) ?? ''}</p>}
    </div>
  );
};
